/* eslint-disable */
import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { PulseLoader } from "react-spinners";
import { Button, Form, Row, Col, InputGroup, FormControl } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import DataTableUI from "../data/DataTableUI";
import { motion, AnimatePresence } from "framer-motion";
import Cookies from "js-cookie";
import { BiLogOut } from "react-icons/bi";

function OrdersDataTable() {
	function handleLgout() {
		Cookies.set("isLoggedIn", "0");
		window.location.href = `/`;
	}

	//Page
	const [dataList, setDataList] = useState([]);
	const [isLoading, setIsloading] = useState(true);
	const [responseStatus, setResponseStatus] = useState(false);
	const [totalCount, setTotalCount] = useState(0);

	//Search
	const [searchTerm, setSearchTerm] = useState("");
	const [filterBy, setFilterBy] = useState("ID");
	useEffect(async () => {
		const ourRequest = axios.CancelToken.source(); // <-- 1st step
		const postData = { apiKey: process.env.REACT_APP_API_KEY };
		await axios
			.post(`${process.env.REACT_APP_BASE_URL}/ordersList`, postData, {
				cancelToken: ourRequest.token, // <-- 2nd step
			})
			.then(
				(response) => {
					setDataList(response.data?.response[1]?.DataList);
					setTotalCount(response.data?.response[0]?.TotalCount);
					setIsloading(false);
					setResponseStatus(false);
				},
				(error) => {
					console.log(error);
					setResponseStatus(true);
				}
			);
		return () => {
			console.log("ordersList unmounted");
			ourRequest.cancel("ordersList Request Has Been Canceled");
		};
	}, []);

	const columns = [
		{
			name: "ID",
			selector: "ID",
			sortable: true,
			id: true,
			width: "80px",
			hide: "sm",
		},
		{
			name: "Name",
			selector: "customerName",
			wrap: true,
		},
		{
			name: "Email",
			selector: "customerEmail",
			hide: "sm",
			wrap: true,
		},
		{
			name: "Mobile",
			selector: "customerMobile",
			wrap: true,
		},
		{
			name: "Date",
			cell: (record) => (
				<React.Fragment>
					<span>{record.orderDateTime}</span>
				</React.Fragment>
			),
		},
	];
	function handleSelectedRows(e) {
		console.log("selectedRowsCount: ", e.selectedCount);
		console.log(JSON.stringify(e.selectedRows, null, 4));
	}

	if (responseStatus) {
		return (
			<div className="col-md-12 d-flex justify-content-center mt-5">
				<p>{responseStatus}</p>
			</div>
		);
	}
	if (isLoading) {
		return (
			<div className="col-md-12 d-flex justify-content-center mt-5">
				<PulseLoader loading={isLoading} size={20} />
			</div>
		);
	}
	const newList = dataList?.filter((el) => {
		if (searchTerm) {
			if (filterBy == "customerName") {
				if (el.customerName.toLowerCase().includes(searchTerm)) {
					return dataList;
				}
			} else if (filterBy == "customerMobile") {
				if (el.customerMobile.toString().toLowerCase().includes(searchTerm)) {
					return dataList;
				}
			} else {
				if (el.ID.toString().indexOf(searchTerm) > -1) {
					return dataList;
				}
			}
		} else {
			return dataList;
		}
	});
	if (!isLoading && !responseStatus) {
		return (
			<React.Fragment>
				<AnimatePresence>
					<motion.div
						key="child"
						initial="pageInitial"
						animate="pageAnimate"
						exit="PageExit"
						variants={{
							pageInitial: {
								opacity: 0,
							},
							pageAnimate: {
								opacity: 1,
								transition: {
									delay: 0.1,
								},
							},
						}}
						style={{ marginTop: "110px" }}
					>
						<Row>
							<Col md="12" style={{ marginBottom: "20px" }}>
								<Button variant="danger" onClick={handleLgout}>
									<BiLogOut /> Sign Out
								</Button>
							</Col>
							<Col>
								<InputGroup className="mb-3">
									<InputGroup.Text id="basic-addon1">
										<BsSearch />
									</InputGroup.Text>
									<FormControl
										type="text"
										placeholder="..."
										aria-label="Search"
										aria-describedby="basic-addon1"
										value={searchTerm}
										onChange={(e) => setSearchTerm(e.target.value)}
									/>
								</InputGroup>
							</Col>
							<Col>
								<Form.Group>
									<Form.Control as="select" value={filterBy} onChange={(e) => setFilterBy(e.target.value)}>
										<option value="ID">ID</option>
										<option value="customerName">Name</option>
										<option value="customerMobile">Mobile</option>
									</Form.Control>
								</Form.Group>
							</Col>
						</Row>

						<DataTableUI
							columns={columns}
							dataList={newList}
							totalCount={totalCount}
							handleSelectedRows={handleSelectedRows}
							tableTitle="Orders List"
							listURL="ordersList"
							setDataList={setDataList}
							setTotalCount={setTotalCount}
							setIsloading={setIsloading}
						/>
					</motion.div>
				</AnimatePresence>
			</React.Fragment>
		);
	}
}

export default OrdersDataTable;
