/* eslint-disable */
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import RecordModal from "./RecordModal";
import axios from "axios";
function DataTableUI({
	columns,
	dataList,
	totalCount,
	handleSelectedRows,
	tableTitle,
	handlePageChange,
	handleOnChangeRowsPerPage,
	listURL,
	setDataList,
	setTotalCount,
}) {
	const [recordModalStatus, setRecordModalStatus] = useState(false);
	const [recordModalData, setRecordModalData] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);

	function handleOnRowClickedData(record) {
		setRecordModalData(record);
		setRecordModalStatus(true);
	}

	async function handlePageChange(e) {
		console.log("currentPage: ", e);
		await axios
			.post(`${process.env.REACT_APP_BASE_URL}/${listURL}`, {
				apiKey: process.env.REACT_APP_API_KEY,
				data: {
					Page: e,
					perPage: perPage,
				},
			})
			.then(
				(response) => {
					setDataList(response.data.response[1].DataList);
					setTotalCount(response.data.response[0].TotalCount);
				},
				(error) => {
					console.log(error);
					setDataList([]);
					setTotalCount(0);
				}
			);
	}

	async function handleOnChangeRowsPerPage(e) {
		console.log("Per Page: ", e);
		setPerPage(e);
		// handlePageChange(currentPage, perPage);
		await axios
			.post(`${process.env.REACT_APP_BASE_URL}/${listURL}`, {
				apiKey: process.env.REACT_APP_API_KEY,
				data: {
					Page: 1,
					perPage: e,
				},
			})
			.then(
				(response) => {
					setDataList(response.data.response[1].DataList);
					setTotalCount(response.data.response[0].TotalCount);
				},
				(error) => {
					console.log(error);
					setDataList([]);
					setTotalCount(0);
				}
			);
	}

	return (
		<React.Fragment>
			<DataTable
				keyField="ID"
				responsive={true}
				highlightOnHover={true}
				defaultSortAsc={false}
				defaultSortField="ID"
				title={tableTitle}
				columns={columns}
				data={dataList}
				paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}
				selectableRows={true}
				onSelectedRowsChange={handleSelectedRows}
				onRowClicked={handleOnRowClickedData}
				pagination={true}
				paginationServer={true}
				paginationTotalRows={totalCount}
				paginationPerPage={10}
				onChangePage={handlePageChange}
				onChangeRowsPerPage={handleOnChangeRowsPerPage}
			/>
			{recordModalStatus && <RecordModal recordModalData={recordModalData} recordModalStatus={recordModalStatus} changeRecordModalStats={setRecordModalStatus} />}
		</React.Fragment>
	);
}

export default DataTableUI;
