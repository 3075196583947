/* eslist-disabled */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const getMenuAPI = createAsyncThunk("menu/getMenuAPI", async (payload) => {
	const menu = await axios.get(`${process.env.REACT_APP_BASE_URL}/menu-list/${payload.restId}`);
	const menuList = menu.data.sortedMenu;
	return { menu: menuList };
});

const menuSlice = createSlice({
	name: "menu",
	initialState: [],
	extraReducers: {
		[getMenuAPI.fulfilled]: (state, action) => {
			// console.log(JSON.stringify(action.payload, null, 4));
			return action.payload.menu;
		},
	},
});

export default menuSlice.reducer;
