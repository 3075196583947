/* eslint-disable */
import React from "react";
import { Button, Modal, Row, Col, ListGroup, Image } from "react-bootstrap";
function RecordModal(props) {
	return (
		<div>
			<Modal show={props.recordModalStatus} onHide={() => props.changeRecordModalStats(false)}>
				<Modal.Header>
					<Modal.Title style={{ alignContent: "center" }}>{props.recordModalData.ID}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col>
							{Object.entries(props.recordModalData).map(([key, value], index) => (
								<ListGroup key={index}>
									<ListGroup.Item style={{ wordBreak: "break-all" }}>
										{key.toString().includes("img") || key.toString().includes("img1") ? (
											<React.Fragment>
												<b>{key}:</b>{" "}
												<a href={value} target="_blank">
													<Image src={value} style={{ maxWidth: "55px", height: "auto" }} thumbnail />
												</a>
											</React.Fragment>
										) : (
											<React.Fragment>
												<b>{key}:</b> {value}
											</React.Fragment>
										)}
									</ListGroup.Item>
								</ListGroup>
							))}
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => props.changeRecordModalStats(false)}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export default RecordModal;
