/* eslist-disabled */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const GetBranchInfo = createAsyncThunk("menu/GetBranchInfo", async (payload) => {
	const ourRequest = axios.CancelToken.source();
	const postData = { apiKey: process.env.REACT_APP_API_KEY, RestaurantID: payload.restId };
	console.log(JSON.stringify(payload.restId, null, 4));
	const branchInfo = await axios
		.post(`${process.env.REACT_APP_BASE_URL}/branch-info`, postData, {
			cancelToken: ourRequest.token,
		})
		.then(
			(response) => {
				return response;
			},
			(error) => {
				console.log(error);
				return error;
			}
		);
	let branch = branchInfo.data.response;
	// console.log(JSON.stringify(branch, null, 4));
	return { branch: branch };
});

const branchSlice = createSlice({
	name: "branch",
	initialState: [],
	extraReducers: {
		[GetBranchInfo.fulfilled]: (state, action) => {
			return action.payload.branch;
		},
	},
});

export default branchSlice.reducer;
