/* eslint-disable */
import React, { Fragment } from "react";
import { Navbar, Image, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function Banner() {
	return (
		<Navbar collapseOnSelect fixed="top" expand="lg" bg="light" variant="dark" className="d-flex justify-content-between">
			<Link to="/">
				<Image src={`${process.env.PUBLIC_URL}/logo192.png`} style={{ width: "100%", height: "auto", maxWidth: "100px" }} />
			</Link>
			<h4>
				<span style={{ color: "rgb(243, 121, 52)" }}>
					<em>
						<strong>
							<span style={{ fontSize: "14px" }}>
								&quot; IF IT SWIM, <br /> WE HAVE IT &quot;
							</span>
						</strong>
					</em>
				</span>
			</h4>
		</Navbar>
	);
}

export default Banner;
