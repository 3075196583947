/* eslint-disable */
import axios from "axios";
import Swal from "sweetalert2";
var Toast = Swal.mixin({
	toast: true,
	position: "center",
	showConfirmButton: false,
	timer: 2000,
	timerProgressBar: true,
	didOpen: (toast) => {
		toast.addEventListener("mouseenter", Swal.stopTimer);
		toast.addEventListener("mouseleave", Swal.resumeTimer);
	},
});

const Helpers = {
	handlePush: async function handlePush(setIsloading, currentData, setIsLoggedIn, setAdminRole) {
		setIsloading(true);
		await axios
			.post(`${process.env.REACT_APP_BASE_URL}/admin-login`, {
				apiKey: process.env.REACT_APP_API_KEY,
				data: currentData,
			})
			.then(
				(response) => {
					if (response.data.result == 0) {
						Toast.fire({
							title: "Success!",
							text: response.data.msg,
							icon: "success",
						});
						console.log("response.data: ", response.data);
						setAdminRole(response.data.response.ID);
						setIsLoggedIn("login");
						setIsloading(false);
					} else {
						Toast.fire({
							title: "Error!",
							text: response.data.msg,
							icon: "error",
						});
						setIsloading(false);
					}
				},
				(error) => {
					console.log(error);
					Toast.fire({
						title: "Error!",
						text: error,
						icon: "error",
					});
					setIsloading(false);
				}
			);
	},
};

export default Helpers;
