/* eslint-disable */
import React from "react";
import { Route, Redirect } from "react-router-dom";
import Cookies from "js-cookie";

const PrivateRoute = ({ component: Component, ...rest }) => {
	return (
		// Show the component only when the user is logged in
		// Otherwise, redirect the user to /signin page
		<Route {...rest} render={(props) => (Cookies.get("isLoggedIn") == "183" ? <Component {...props} /> : <Redirect to="/" />)} />
	);
};

export default PrivateRoute;
