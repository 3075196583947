import { configureStore } from "@reduxjs/toolkit";
import menuReducer from "./menuSlice";
import branchReducer from "./branchSlice";
import ramandanMenuReducer from "./ramandanMenuSlice";

export default configureStore({
	reducer: {
		menu: menuReducer,
		branch: branchReducer,
		ramandanMenu: ramandanMenuReducer,
	},
});
