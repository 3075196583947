/* eslint-disable */
import React, { Fragment, useState } from "react";
import { Button, Form, Col, Row, FormControl, Card, Modal, Table, Container } from "react-bootstrap";
function FullyBooked() {
	return (
		<Container fluid>
			<Row style={{ marginTop: "110px" }}>
				<Col md="12">
					<Fragment>
						<Col md="12" style={{ marginBottom: "20px" }}>
							<h3 className="d-flex justify-content-center">Sorry, We're Fully Booked This Week</h3>
							<br />
							<h3 className="d-flex justify-content-center">عذرا، الحجوزات اكتملت لهذا الاسبوع</h3>
						</Col>
					</Fragment>
				</Col>
			</Row>
		</Container>
	);
}

export default FullyBooked;
