/* eslint-disable */

import React, { Fragment, useState, useEffect } from "react";
import { Button, Col, Row, ListGroup, Modal, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getMenuAPI } from "../../redux/ramandanMenuSlice";
import { GetBranchInfo } from "../../redux/branchSlice";
import { PulseLoader } from "react-spinners";
import { Link } from "react-scroll";
import { AiFillInstagram } from "react-icons/ai";
import { ImLocation2 } from "react-icons/im";
import { FaMobileAlt, FaLaptop } from "react-icons/fa";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FaWhatsapp } from "react-icons/fa";
import { MdLanguage } from "react-icons/md";
import axios from "axios";
function RamadanSpecialMenu(props) {
	const restId = props.match.params.restId;
	const dispatch = useDispatch();

	const [itemId, setItemId] = useState(0);
	const [foodImgURL, setFoodImgURL] = useState(0);
	const [foodDesc, setFoodDesc] = useState("");
	const [foodPrice, setFoodPrice] = useState(0);
	const [foodNameEn, setFoodNameEn] = useState("");
	const [foodAddonsList, setFoodAddonsList] = useState([]);
	const [isLoading, setIsloading] = useState(true);
	const [isLoadingAddons, setIsloadingAddons] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const [currentLang, setCurrentLang] = useState("ar");
	const [currenrDir, setCurrentDir] = useState("rtl");
	const [currenrTextAlign, setCurrentTextAlign] = useState("right");
	const handleCloseModal = () => {
		setFoodAddonsList([]);
		setShowModal(false);
	};
	useEffect(() => {
		dispatch(getMenuAPI({ restId }));
		dispatch(GetBranchInfo({ restId }));
		currentLang == "ar" ? setCurrentDir("rtl") : setCurrentDir("ltr");
		return () => {};
	}, [dispatch]);

	const menu = useSelector((state) => {
		if (currentLang == "ar") {
			return state.menu.sortedMenuAr;
		} else {
			return state.menu.sortedMenu;
		}
	});

	const branch = useSelector((state) => {
		return state.branch;
	});

	const branchInfo = branch[0]?.branches[0];
	const restStatus = branch[0]?.restStatus;
	const restaurantImg = branchInfo?.restaurant_img;
	useEffect(() => {
		if (menu && branch[0]?.branches.length) {
			setIsloading(false);
		}
		return () => {};
	}, [menu, branchInfo]);

	function getAddons(e, el) {
		setIsloadingAddons(true);
		let itemId = el.ID;

		const ourRequest = axios.CancelToken.source();
		const postData = { apiKey: process.env.REACT_APP_API_KEY, itemId: itemId };
		axios
			.post(`${process.env.REACT_APP_BASE_URL}/food-addon`, postData, {
				cancelToken: ourRequest.token,
				"Content-Type": "application/json",
			})
			.then(
				(response) => {
					setFoodAddonsList(response.data.response);
					setIsloadingAddons(false);
				},
				(error) => {
					console.log(error);
				}
			);

		setItemId(itemId);
		setFoodImgURL(el.food_img);
		currentLang == "ar"
			? (setFoodDesc(el.food_desc_arabic), setFoodPrice(el.food_price), setFoodNameEn(el.food_name_arabic))
			: (setFoodDesc(el.food_desc), setFoodPrice(el.food_price), setFoodNameEn(el.food_name));

		setShowModal(true);
		e.preventDefault();
	}

	function changeLang(e, currentLang) {
		e.preventDefault();
		currentLang == "ar"
			? (setCurrentLang("en"), setCurrentDir("ltr"), setCurrentTextAlign("left"))
			: (setCurrentLang("ar"), setCurrentDir("rtl"), setCurrentTextAlign("right"));
	}

	function createMarkup(text) {
		return { __html: text };
	}

	if (isLoading) {
		return (
			<Row>
				<div className="col-md-12 d-flex justify-content-center" style={{ marginTop: "180px" }}>
					<PulseLoader loading={isLoading} size={20} />
				</div>
			</Row>
		);
	}
	if (!isLoading) {
		return (
			<Fragment>
				<Row>
					<Col
						md="12"
						className="menuBg"
						style={{
							marginTop: "110px",
							background: `linear-gradient(0deg, rgb(196, 117, 37, 0.7), rgb(196, 117, 37, 0.4)), url("${restaurantImg}")`,
							backgroundPosition: "center",
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover",
							backgroundBlendMode: "color",
						}}
					>
						<div className="content">
							<Col md="12" className="d-flex justify-content-center mt-5 mb-5">
								<h2 style={{ color: "white" }}>
									{branchInfo?.branch_name} - {branchInfo?.branch_location}
								</h2>
							</Col>
							<Col md="12 d-flex justify-content-around  mt-5 mb-5">
								{restStatus == "open" ? (
									<h5 style={{ color: "white", textTransform: "uppercase" }}>
										<div className="statusOpen"></div> {restStatus}
									</h5>
								) : (
									<h5 style={{ color: "white", textTransform: "uppercase" }}>
										<div className="statusClosed"></div> {restStatus}
									</h5>
								)}
							</Col>
						</div>
					</Col>
				</Row>
				<Row>
					<Col md="12">
						<div className="d-flex justify-content-between mt-3">
							<a href={`tel:${branchInfo?.branch_contact_number}`} className="contactIcon">
								<FaMobileAlt style={{ fontSize: "30px", color: "white" }} />
							</a>
							<a href="https://wa.me/97317776999" target="_blank" className="contactIcon">
								<FaWhatsapp style={{ fontSize: "30px", color: "white" }} />
							</a>
							<a href={`http://maps.google.com/?q=${branchInfo?.branch_loc}&zoom=17&mapmode=streetview`} target="_blank" className="contactIcon">
								<ImLocation2 style={{ fontSize: "30px", color: "white" }} />
							</a>
							<a href={branchInfo?.social_ig} target="_blank" className="contactIcon">
								<AiFillInstagram style={{ fontSize: "30px", color: "white" }} />
							</a>
							<a href={branchInfo?.social_website} target="_blank" className="contactIcon">
								<FaLaptop style={{ fontSize: "30px", color: "white" }} />
							</a>

							<a href="#" onClick={(e) => changeLang(e, currentLang)} className="contactIcon">
								<MdLanguage style={{ fontSize: "30px", color: "white" }} />
								{/* {currentLang} */}
							</a>
						</div>
					</Col>
				</Row>
				{currentLang == "ar" ? (
					<Row dir={`${currenrDir}`}>
						<Col md="12" style={{ marginTop: "20px", textAlign: currenrTextAlign }}>
							<div className="foodcategmenu brandcolor">
								{Object.entries(menu).map(([key, value], index) => (
									<Link to={key} spy={true} smooth={true} key={index}>
										<a className="brandcolor" href={`#${key}`}>
											{key}
										</a>
									</Link>
								))}
							</div>

							{Object.entries(menu).map(([key, value], index) => (
								<Row key={index} style={{ margin: "40px 5px 40px 5px" }} dir={`${currenrDir}`}>
									<Col md="12">
										<h4 className="brandcolor2" id={key}>
											<b>{key}</b>
										</h4>
									</Col>
									<Col md="12">
										<Row>
											{value.map((el, i) => (
												<div className="col-md-6 col-xs-6 menuitembox2" key={i}>
													<a href="#" key={i} style={{ textDecoration: "none", color: "grey" }} onClick={(e) => getAddons(e, el)}>
														<div>
															<Row>
																<Col xs="4">
																	<LazyLoadImage height="auto" src={el.food_img} width="100%" />
																</Col>
																<Col xs="8">
																	<div className="d-flex justify-content-left">
																		<p className="textoneLine" style={{ margin: "5px 10px 5px 10px" }}>
																			<b>{el.food_name_arabic}</b>
																		</p>
																	</div>
																	<div className="d-flex justify-content-left">
																		<p className="textoneLine" style={{ margin: "5px 10px 5px 10px" }}>
																			{el.food_desc_arabic}
																		</p>
																	</div>
																	<div className="d-flex justify-content-end" style={{ marginTop: "10px" }}>
																		<p style={{ margin: "5px 10px 5px 10px" }}>
																			<b>{el.food_price == 0 ? "Price On Selection" : `${parseFloat(el.food_price).toFixed(3)} BHD`}</b>
																		</p>
																	</div>
																</Col>
															</Row>
														</div>
													</a>
												</div>
											))}
										</Row>
									</Col>
								</Row>
							))}
						</Col>
					</Row>
				) : (
					<Row dir={`${currenrDir}`}>
						<Col md="12" style={{ marginTop: "20px", textAlign: currenrTextAlign }}>
							<div className="foodcategmenu brandcolor">
								{Object.entries(menu).map(([key, value], index) => (
									<Link to={key} spy={true} smooth={true} key={index}>
										<a className="brandcolor" href={`#${key}`}>
											{key}
										</a>
									</Link>
								))}
							</div>

							{Object.entries(menu).map(([key, value], index) => (
								<Row key={index} style={{ margin: "40px 5px 40px 5px" }} dir={`${currenrDir}`}>
									<Col md="12">
										<h4 className="brandcolor2" id={key}>
											<b>{key}</b>
										</h4>
									</Col>
									<Col md="12">
										<Row>
											{value.map((el, i) => (
												<div className="col-md-6 col-xs-6 menuitembox2" key={i}>
													<a href="#" key={i} style={{ textDecoration: "none", color: "grey" }} onClick={(e) => getAddons(e, el)}>
														<div>
															<Row>
																<Col xs="4">
																	<LazyLoadImage height="auto" src={el.food_img} width="100%" />
																</Col>
																<Col xs="8">
																	<div className="d-flex justify-content-left">
																		<p className="textoneLine" style={{ margin: "5px 10px 5px 10px" }}>
																			<b>{el.food_name}</b>
																		</p>
																	</div>
																	<div className="d-flex justify-content-left">
																		<p className="textoneLine" style={{ margin: "5px 10px 5px 10px" }}>
																			{el.food_desc}
																		</p>
																	</div>
																	<div className="d-flex justify-content-end" style={{ marginTop: "10px" }}>
																		<p style={{ margin: "5px 10px 5px 10px" }}>
																			<b>{el.food_price == 0 ? "Price On Selection" : `${parseFloat(el.food_price).toFixed(3)} BHD`}</b>
																		</p>
																	</div>
																</Col>
															</Row>
														</div>
													</a>
												</div>
											))}
										</Row>
									</Col>
								</Row>
							))}
						</Col>
					</Row>
				)}
				{/* <Row dir={`${currenrDir}`}>
					<Col md="12" style={{ marginTop: "20px", textAlign: currenrTextAlign }}>
						<div className="foodcategmenu brandcolor">
							{Object.entries(menu).map(([key, value], index) => (
								<Link to={key} spy={true} smooth={true} key={index}>
									<a className="brandcolor" href={`#${key}`}>
										{key}
									</a>
								</Link>
							))}
						</div>

						{Object.entries(menu).map(([key, value], index) => (
							<Row key={index} style={{ margin: "40px 5px 40px 5px" }} dir={`${currenrDir}`}>
								<Col md="12">
									<h4 className="brandcolor2" id={key}>
										<b>{key}</b>
									</h4>
								</Col>
								<Col md="12">
									<Row>
										{value.map((el, i) => (
											<div className="col-md-6 col-xs-6 menuitembox2" key={i}>
												<a href="#" key={i} style={{ textDecoration: "none", color: "grey" }} onClick={(e) => getAddons(e, el)}>
													<div>
														<Row>
															<Col xs="4">
																<LazyLoadImage height="auto" src={el.food_img} width="100%" />
															</Col>
															<Col xs="8">
																<div className="d-flex justify-content-left">
																	<p className="textoneLine" style={{ margin: "5px 10px 5px 10px" }}>
																		<b>{el.food_name}</b>
																	</p>
																</div>
																<div className="d-flex justify-content-left">
																	<p className="textoneLine" style={{ margin: "5px 10px 5px 10px" }}>
																		{el.food_desc}
																	</p>
																</div>
																<div className="d-flex justify-content-end" style={{ marginTop: "10px" }}>
																	<p style={{ margin: "5px 10px 5px 10px" }}>
																		<b>{el.food_price == 0 ? "Price On Selection" : `${parseFloat(el.food_price).toFixed(3)} BHD`}</b>
																	</p>
																</div>
															</Col>
														</Row>
													</div>
												</a>
											</div>
										))}
									</Row>
								</Col>
							</Row>
						))}
					</Col>
				</Row> */}
				<Row>
					<Col md="12" className="d-flex justify-content-center">
						<p>
							© All rights powered by <span style={{ color: "#06bee9" }}>FLASHH Bahrain 2021</span>
						</p>
					</Col>
				</Row>
				{foodNameEn && (
					<Modal show={showModal} onHide={handleCloseModal}>
						<Modal.Header closeButton>
							<Modal.Title>{foodNameEn}</Modal.Title>
						</Modal.Header>
						<Modal.Body style={{ height: "auto" }}>
							<Row>
								<Col md="12">
									<LazyLoadImage
										height="auto"
										src={foodImgURL} // use normal <img> attributes as props
										width="100%"
									/>
								</Col>

								{foodDesc && (
									<Col md="12" className="mt-3">
										<p style={{ textAlign: "justify" }}>{foodDesc}</p>
									</Col>
								)}
								{isLoadingAddons && (
									<div className="col-md-12 d-flex justify-content-center mt-2 mb-2">
										<PulseLoader loading={isLoadingAddons} size={20} />
									</div>
								)}
								{!isLoadingAddons && foodAddonsList && (
									<Col md="12" className="mt-3">
										{Object.entries(foodAddonsList).map(([key, value], index) => (
											<Fragment>
												<p key={index}>
													<b>{key}</b>
												</p>
												<ListGroup>
													{value.map((el, i) => (
														<ListGroup.Item className="d-flex justify-content-between">
															<span>{el.addon_name}:</span> <span>{parseFloat(el.addon_price).toFixed(3)} BHD</span>
														</ListGroup.Item>
													))}
												</ListGroup>
											</Fragment>
										))}
									</Col>
								)}
								{foodPrice != 0 && (
									<Col md="12" className="mt-3">
										<p>
											<b>Price: {foodPrice != 0 ? `${parseFloat(foodPrice).toFixed(3)} BHD` : null} </b>
										</p>
									</Col>
								)}
							</Row>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={handleCloseModal}>
								Close
							</Button>
						</Modal.Footer>
					</Modal>
				)}
			</Fragment>
		);
	}
}

export default RamadanSpecialMenu;
