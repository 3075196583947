/* eslint-disable */

import React from "react";
import { Row, Col, Container } from "react-bootstrap";
export default function Layout(props) {
	return (
		<React.Fragment>
			<Container>{props.children}</Container>
		</React.Fragment>
	);
}
