/* eslint-disable */
import React, { useState, useEffect } from "react";
import MotionLayout from "../layout/MotionLayout";
import { Button, Form, Col, Row, FormControl } from "react-bootstrap";
import { PulseLoader } from "react-spinners";
import { FaLock } from "react-icons/fa";
import Helpers from "./Helpers";
import Cookies from "js-cookie";
import { BiLogOut, BiLogIn } from "react-icons/bi";

export default function LoginForm() {
	const [isLoading, setIsloading] = useState(false);
	const [Email, setEmail] = useState("");
	const [Password, setPassword] = useState("");
	const [isloggedIn, setIsLoggedIn] = useState("");
	const [adminRole, setAdminRole] = useState(0);
	const currentData = {
		Email: Email,
		Password: Password,
	};
	useEffect(() => {
		Cookies.set("isLoggedIn", "");

		if (Cookies.get("isLoggedIn") != "") {
			window.location.href = `${process.env.PUBLIC_URL}/orders-list`;
		}
		if (isloggedIn == "login") {
			Cookies.set("isLoggedIn", adminRole);
			adminRole == 183 ? (window.location.href = `${process.env.PUBLIC_URL}/orders-list`) : "";
		}
		return () => {
			// cleanup
		};
	}, [isloggedIn]);
	return (
		<MotionLayout>
			{isLoading && (
				<div className="col-md-12 d-flex justify-content-center mt-2">
					<PulseLoader loading={isLoading} size={20} />
				</div>
			)}
			{!isLoading && (
				<Row style={{ marginTop: "110px" }}>
					<Col className="col-md-7 offset-md-2">
						<Form className="form-horizontal">
							<Form.Group>
								<Form.Label>Email Address</Form.Label>
								<FormControl type="email" placeholder="..." value={Email} onChange={(e) => setEmail(e.target.value)} />
							</Form.Group>
							<Form.Group>
								<Form.Label>Password</Form.Label>
								<FormControl type="password" placeholder="..." value={Password} onChange={(e) => setPassword(e.target.value)} required />
							</Form.Group>
							<Form.Group>
								<Button variant="success" type="submit" onClick={() => Helpers.handlePush(setIsloading, currentData, setIsLoggedIn, setAdminRole)}>
									<BiLogIn /> Sign In
								</Button>
							</Form.Group>
						</Form>
					</Col>
				</Row>
			)}
		</MotionLayout>
	);
}
