import React from "react";
import { motion, AnimatePresence } from "framer-motion";
export default function MotionLayout(props) {
	return (
		<AnimatePresence>
			<motion.div
				key="child"
				initial="pageInitial"
				animate="pageAnimate"
				exit="PageExit"
				variants={{
					pageInitial: {
						opacity: 0,
					},
					pageAnimate: {
						opacity: 1,
						transition: {
							delay: 0.1,
						},
					},
					// PageExit: {
					// 	backgroundColor: "white",
					// 	opacity: 0.4,
					// },
				}}
			>
				{props.children}
			</motion.div>
		</AnimatePresence>
	);
}
