/* eslint-disable */

import React, { useState, useEffect } from "react";
import { Form, Col, Row, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetBranchInfo } from "../../redux/branchSlice";
import { AiFillInstagram } from "react-icons/ai";
import { ImLocation2 } from "react-icons/im";
import { FaMobileAlt, FaLaptop } from "react-icons/fa";

import { FaWhatsapp } from "react-icons/fa";
function Home() {
   const [chosenRest, setChosenRest] = useState(304);
   const restId = 304;
   const dispatch = useDispatch();
   useEffect(() => {
      dispatch(GetBranchInfo({ restId }));
      return () => {};
   }, [dispatch]);

   const branch = useSelector((state) => {
      return state.branch;
   });
   const branchInfo = branch[0]?.branches[0];
   return (
      <Row>
         <div className="bgHome">
            <div className="content2">
               <Col md="12" className="pt-5 pr-5 pl-5">
                  <div className="d-flex justify-content-between mt-3">
                     <a href={`tel:${branchInfo?.branch_contact_number}`} className="contactIcon">
                        <FaMobileAlt style={{ fontSize: "30px", color: "white" }} />
                     </a>
                     <a href="https://wa.me/97317776999" target="_blank" className="contactIcon">
                        <FaWhatsapp style={{ fontSize: "30px", color: "white" }} />
                     </a>
                     <a href={`http://maps.google.com/?q=${branchInfo?.branch_loc}&zoom=17&mapmode=streetview`} target="_blank" className="contactIcon">
                        <ImLocation2 style={{ fontSize: "30px", color: "white" }} />
                     </a>
                     <a href={branchInfo?.social_ig} target="_blank" className="contactIcon">
                        <AiFillInstagram style={{ fontSize: "30px", color: "white" }} />
                     </a>
                     <a href={branchInfo?.social_website} target="_blank" className="contactIcon">
                        <FaLaptop style={{ fontSize: "30px", color: "white" }} />
                     </a>
                  </div>
               </Col>
               <Col md="12" className="p-5">
                  <Form.Group>
                     <FormControl as="select" onChange={(e) => setChosenRest(e.target.value)}>
                        <option value="0">Choose Branch</option>
                        <option value="304" selected>
                           Dine In / Take Away
                        </option>
                     </FormControl>
                  </Form.Group>

                  <Form.Group>
                     <Link className="btn btn-primary noBorder" to={`/menu/${chosenRest}`} style={{ width: "100%", background: "#c47525", fontSize: "18px" }}>
                        Show Menu
                     </Link>
                  </Form.Group>
                  {/* 
						<Form.Group>
							<Link className="btn btn-primary noBorder" to="/business-plans" style={{ width: "100%", background: "#D1431D", fontSize: "18px" }}>
								Business Lunch Plans
							</Link>
						</Form.Group> */}

                  {/* <Form.Group>
							<Link className="btn btn-primary noBorder" to={`/ramadan-special-menu/${chosenRest}`} style={{ width: "100%", background: "#D1431D", fontSize: "18px" }}>
								Ramadan Special Menu
							</Link>
						</Form.Group> */}
               </Col>
            </div>
         </div>
      </Row>
   );
}
export default Home;
