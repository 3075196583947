/* eslint-disable */

import React, { Fragment } from "react";
import Layout from "./components/layout/Layout";
import Banner from "./components/menu/Banner";
import PrivateRoute from "./components/routes/PrivateRoute";
import Home from "./components/home/Home";
import RestaurantMenu from "./components/restMenu/RestaurantMenu";
import RamadanSpecialMenu from "./components/restMenu/RamadanSpecialMenu";
import BusinessPlansSteps from "./components/businessPlans/BusinessPlansSteps";
import FullyBooked from "./components/businessPlans/FullyBooked";
import OrdersDataTable from "./components/orders/OrdersDataTable";
import LoginForm from "./components/login/LoginForm";
// import { FaWhatsapp } from "react-icons/fa";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivacyPage from "./components/PrivacyPage";
import TANDC from "./components/TANDC";

function App() {
   return (
      <Router basename={process.env.PUBLIC_URL}>
         {/* <a className="float" href="https://wa.me/97317776999" target="_blank">
				<FaWhatsapp style={{ width: "100%", height: "auto", maxWidth: "40px" }} className="my-float" />
			</a> */}
         <Banner />
         <Layout>
            <Switch>
               <PrivateRoute path="/orders-list" exact component={() => <OrdersDataTable />} />
               <Route path="/login" exact component={() => <LoginForm />} />
               {/* <Route path="/business-plans" exact component={() => <BusinessPlansSteps />} /> */}
               <Route path="/business-plans" exact component={() => <FullyBooked />} />
               <Route path="/" exact component={() => <Home />} />
               <Route path="/privacy" exact component={() => <PrivacyPage />} />
               <Route path="/tandc" exact component={() => <TANDC />} />
               <Route path="/menu/:restId" exact component={(props) => <RestaurantMenu {...props} />} />
               {/* <Route path="/ramadan-special-menu/:restId" exact component={(props) => <RamadanSpecialMenu {...props} />} /> */}
            </Switch>
         </Layout>
      </Router>
   );
}

export default App;
